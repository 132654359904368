<template>
  <a-modal
    title="打款信息"
    :visible="visible"
    :maskClosable="false"
    @ok="handleCancel"
    @cancel="handleCancel"
  >
    <div class="content">
      <div class="content-item">
        <span class="title">公司主体：</span>
        <span class="desc">{{ detail.company_name }}</span>
      </div>
      <div class="content-item">
        <span class="title">收款账号：</span>
        <span class="desc">{{ detail.bank_account }}</span>
      </div>
      <div class="content-item">
        <span class="title">开户行：</span>
        <span class="desc">{{ detail.bank_branch }}</span>
      </div>
      <div class="content-item">
        <span class="title">结算金额：</span>
        <span class="desc">{{ $pub.KEEP_NUMBER_DECIMAL((detail.cash_amount || 0) / 100, 2, false, true) }}</span>
      </div>
    </div>
    <template #footer>
      <a-button @click="handleCancel">关闭</a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
  data () {
    return {
      // 详情
      detail: {},
      // 是否展示
      visible: false,
    }
  },
  methods: {
    // 显示
    showModal (record) {
      this.detail = record
      this.visible = true
    },
    // 关闭
    handleCancel () {
      // 隐藏
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.content-item {
  line-height: 26px;
  .title {
    font-size: 14px;
    color: #7A7A7A;
  }
  .desc {
    font-weight: 500;
    font-size: 14px;
    color: #5A5A5A;
  }
}
</style>
